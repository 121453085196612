<template>
  <div class="px-4">
    <div>
      <div class="d-flex flex-row align-center justify-space-between mt-3">
        <tp-select
          v-model="saleTabActive.site"
          :items="ARRAY_LIST_MODEL_SITES"
          :readonly="
            saleTabActive.status === 4 ||
              saleTabActive.cancel_status === 1 ||
              saleTabActive.order_channel === 1
          "
          hide-details="auto"
          item-text="text"
          item-value="id"
          label="Thương hiệu bán"
          placeholder="Thương hiệu bán"
        ></tp-select>
      </div>

      <v-divider class="my-4"></v-divider>

      <div v-if="saleTabActive.order" class="d-flex justify-space-between">
        <span class="mr-5">Mã đơn hàng: </span>
        <button
          class="primary--text"
          @click="viewDetailOrder(saleTabActive.order)"
        >
          {{ saleTabActive.order.code }}
        </button>
      </div>

      <div class="d-flex flex-row align-center justify-space-between mt-4">
        <div class="">
          <span>Tổng tiền hàng </span>
          <span> ({{ goodsAmountTotal.quantity }} sp) </span>
        </div>
        <div>
          {{ goodsAmountTotal.value | formatCurrency }}
        </div>
      </div>

      <div
        v-if="saleTabActive.sc_code"
        class="d-flex flex-row align-center justify-space-between mt-3"
      >
        <div class="d-flex align-center">
          <span>Phí sửa chữa</span>

          <button
            class="ml-1 primary--text"
            @click="viewDetailRepair(saleTabActive.sc_id)"
          >
            ({{ saleTabActive.sc_code }})
          </button>
        </div>
        <div>
          {{ saleTabActive.sc_fee | formatCurrency }}
        </div>
      </div>

      <div class="d-flex flex-row align-center justify-space-between mt-3">
        <div class="">Giảm giá hóa đơn</div>
        <div>
          -{{ parseInt(saleTabActive.promotionReduce) | formatCurrency }}
        </div>
      </div>

      <template v-if="saleTabActive.return_goods_code">
        <v-divider class="my-4"></v-divider>

        <div class="d-flex flex-row align-center justify-space-between mt-3">
          <div class="">Tồng tiền hóa đơn mua</div>
          <div>
            {{ saleTabActive.value | formatCurrency }}
          </div>
        </div>

        <div class="d-flex flex-row align-center justify-space-between mt-3">
          <div class=" d-flex flex-col">
            <span>Tổng tiền phiếu trả</span>
            <button
              class="primary--text"
              @click="viewDetailReturn(saleTabActive.return_goods_id)"
            >
              ({{ saleTabActive.return_goods_code }})
            </button>
          </div>
          <div>
            {{ saleTabActive.return_goods_value | formatCurrency }}
          </div>
        </div>
      </template>

      <v-divider class="my-4"></v-divider>

      <div class="d-flex flex-row align-center justify-space-between mt-3">
        <div class="font-weight-bold">Khách cần trả</div>
        <div class="font-weight-bold red--text text-h6">
          {{ khachCanTra | formatCurrency }}
        </div>
      </div>

      <div v-if="saleTabActive.status">
        <div class="d-flex justify-space-between mt-2">
          <span class="mr-5"
            >Khách đã trả <span class="text-body-2">(Thành công)</span>
          </span>
          <span>{{ Number(saleTabActive.total_paid) | formatCurrency }}</span>
        </div>

        <div class="d-flex justify-space-between mt-2">
          <span class="mr-5">Khách còn nợ: </span>
          <span>{{
            Number(saleTabActive.khachhang_con_no) | formatCurrency
          }}</span>
        </div>

        <v-divider class="my-2"></v-divider>

        <div class="d-flex justify-space-between mt-2">
          <span class="mr-5"
            >Khách đã trả <span class="text-body-2">(Đang xử lý)</span>
          </span>
          <span>{{
            Number(saleTabActive.total_transaction_waiting) | formatCurrency
          }}</span>
        </div>
      </div>

      <div v-else>
        <div class="d-flex flex-row align-center justify-space-between mt-3">
          <div class="d-flex align-center">
            <div>Khách thanh toán</div>

            <modal-form-sale-receipt-payment
              :branch-id="saleTabActive.branch_id"
              :total-amount-to-pay="khachCanTra"
              @onSaved="onSavedFormPayment"
            >
              <template #activator>
                <v-btn icon>
                  <v-icon color="primary">mdi-credit-card-outline</v-icon>
                </v-btn>
              </template>
            </modal-form-sale-receipt-payment>
          </div>

          <div>{{ totalAmountPayment | formatCurrency }}</div>
        </div>

        <div v-if="totalAmountPayment">
          <div
            v-if="totalAmountFee"
            class="mt-1 d-flex align-center justify-space-between"
          >
            <div>Phí thanh toán</div>

            <div>{{ totalAmountFee | formatCurrency }}</div>
          </div>

          <div class="mt-1 d-flex align-center justify-space-between">
            <div>Khách còn nợ</div>

            <div>
              {{ khachConNo | formatCurrency }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End: Calculate -->

    <!-- Start: Feedback -->
    <div v-if="saleTabActive.feedback" class="mt-4">
      <div class="font-weight-medium mb-2">Feedback</div>

      <div class="d-flex justify-space-between mt-2">
        <span class="mr-5">Tên, địa chỉ</span>
        <span>{{ saleTabActive.feedback.customer_info }}</span>
      </div>

      <div class="d-flex justify-space-between mt-2">
        <span class="mr-5">Nội dung</span>
        <span>{{ saleTabActive.feedback.content }}</span>
      </div>

      <div
        v-if="
          saleTabActive.feedback.images && saleTabActive.feedback.images.length
        "
        class="d-flex flex-col mt-2"
      >
        <span class="block">Ảnh</span>

        <div class="d-flex flex-wrap gap-3">
          <div
            v-for="(item, index) in saleTabActive.feedback.images"
            :key="index"
            class="mt-2"
          >
            <v-img
              :aspect-ratio="1"
              :src="item"
              class="grey lighten-3 rounded tp-boder-thin"
              height="80px"
              width="80px"
            >
            </v-img>
          </div>
        </div>
      </div>
    </div>
    <!-- End: Feedback -->

    <!-- Start: Note -->
    <div class="mt-4">
      <div class="font-weight-medium mb-2">Ghi chú</div>
      <v-textarea
        v-model="saleTabActive.note"
        :disabled="!!saleTabActive.status"
        class="text-body-1"
        hide-details
        outlined
        placeholder="Nhập ghi chú"
        rows="2"
      ></v-textarea>
    </div>
    <!-- End: Note -->
  </div>
</template>

<script>
import { ARRAY_LIST_MODEL_SITES, LIST_MODEL_TYPES } from "@/core/constant";
import ModalFormSaleReceiptPayment from "./ModalFormSaleReceiptPayment.vue";

export default {
  components: {
    ModalFormSaleReceiptPayment
  },

  data() {
    return {
      priceReduce: 0,
      LIST_MODEL_TYPES,
      ARRAY_LIST_MODEL_SITES,
      totalAmountPayment: 0,
      totalAmountFee: 0
    };
  },

  async created() {
    await this.$store.dispatch("SUPPLIER/getSuppliers", {
      cur_page: 1,
      filter: {
        fromBalance: null,
        fromBuy: null,
        fromDate: null,
        fromReturn: null,
        sup_cate_ids: [3],
        toBalance: null,
        toBuy: null,
        toDate: null,
        toReturn: null
      },
      per_page: 50,
      search: null
    });

    await this.$store.dispatch("EMPLOYEE/getAllEmployees");

    this.initData();
  },

  computed: {
    computedModelSiteConfigs: {
      get() {
        return this.saleModelSiteConfigs;
      },
      set(val) {
        this.$store.dispatch("SALE/setSaleModelSiteConfigs", val);
      }
    },

    saleModelSiteConfigs() {
      return this.$store.getters["SALE/saleModelSiteConfigs"];
    },

    paymentFee() {
      return this.$store.getters["SALE/paymentFee"];
    },

    promotions() {
      return this.$store.getters["SALE/promotions"];
    },

    suppliersTransport() {
      return this.$store.getters["SUPPLIER/suppliers"];
    },

    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },

    goodsAmountTotal() {
      let obj = {
        value: 0,
        quantity: 0
      };
      this.saleTabActive.options.forEach(item => {
        obj.quantity += item.serials.length;
        obj.value +=
          (item.unit_sold_price - item.rebate_amount) * item.serials.length;
      });

      return obj;
    },

    khachCanTra() {
      let totalPrice = this.goodsAmountTotal.value;
      let reduceTotal = this.saleTabActive.promo
        ? this.saleTabActive.promo.money
        : 0;

      // Calculate payment fee
      totalPrice += this.paymentFee;
      // Calculate shipping fee
      totalPrice += this.saleTabActive.phivanchuyen;
      totalPrice += this.saleTabActive.sc_fee;
      totalPrice -= this.saleTabActive.return_goods_value;
      // Calculate new paymentFee - ke toan request
      // totalPrice += this.saleTabActive.payment_fee;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.saleTabActive.totalPrice = totalPrice;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.saleTabActive.reduceTotal = reduceTotal;

      return totalPrice;
    },

    khachConNo() {
      return this.khachCanTra - this.totalAmountPayment;
    }
  },

  methods: {
    initData() {
      this.totalAmountPayment = this.saleTabActive.total_paid;
      this.totalAmountFee = this.saleTabActive.payment_fee;
    },
    onSavedFormPayment(value) {
      this.totalAmountPayment = value.totalAmount;
      this.totalAmountFee = value.totalFee;
      this.$emit("onChangePayment", value.payments);
    },
    async viewDetailOrder(item) {
      await this.$store.dispatch("ORDER/getOrderById", item.id);

      this.$modal.show({
        name: "modal-order"
      });

      // Get order payments
      await this.$store.dispatch("ORDER/getOrderPaymentTransactions", item.id);

      // Get order participant
      await this.$store.dispatch(
        "INVOICE_PARTICIPANT/getInvoiceParticipantsOfInvoice",
        {
          modelName: "App\\Models\\Order",
          modelId: item.id
        }
      );

      // Get order online payments
      this.$store.dispatch("ORDER/getOrderOnlinePayments", item.id);
    },
    async viewDetailRepair(id) {
      window.open(`/#/guarantee/vouchers/detail/${id}`, "_blank");
    },
    async viewDetailReturn(id) {
      await this.$store.dispatch("CUSTOMER_RETURN/getCustomerReturnById", id);

      await this.$store.dispatch(
        "CUSTOMER_RETURN/getCustomerReturnTransactions",
        id
      );

      this.$modal.show({
        name: "modal-customer-return"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs-transaction {
  border-bottom: 1px solid #e5e5e5;

  &--item {
    border-radius: 4px 4px 0 0;
    cursor: pointer;
    flex: 0 0 33.3333%;
    padding: 0.375rem 0;
    text-align: center;
    transition: all 0.25s ease;

    &:hover {
      background-color: #f7f7f7;
    }
  }
}

.icon-card {
  cursor: pointer;
}

.gap-3 {
  gap: 12px;
}
</style>
